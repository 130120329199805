import React from 'react';

const TermsAndConditions = () => {
  return (
    <div>
      <h2>TERMS AND CONDITIONS</h2>
      <p>Last updated February 23, 2024</p>

      <h3>AGREEMENT TO OUR LEGAL TERMS</h3>
      <p>
        We are 5TygaEats ('Company', 'we', 'us', or 'our'), a company registered
        in South Africa at __________, __________.
      </p>
      <p>
        We operate the website{' '}
        <a href='https://royalcradle-shop.onrender.com/'>
          https://royalcradle-shop.onrender.com/
        </a>{' '}
        (the 'Site'), as well as any other related products and services that
        refer or link to these legal terms (the 'Legal Terms') (collectively,
        the 'Services').
      </p>
      <p>
        5TygaEats is a dynamic platform dedicated to revolutionizing the way
        people experience food. We pride ourselves on offering affordable dining
        options while fostering connections between users and local businesses
        in their area. Whether you're craving a hearty meal, a quick snack, or
        something in between, 5TygaEats is here to satisfy your appetite and
        support the culinary community. Join us in exploring a world of
        delicious possibilities, one bite at a time.
      </p>
      <p>
        You can contact us by phone at 0658928881, email at 5tygaeats@gmail.com,
        or by mail to __________, __________, South Africa.
      </p>
      <p>
        These Legal Terms constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity ('you'), and
        5TygaEats, concerning your access to and use of the Services. You agree
        that by accessing the Services, you have read, understood, and agreed to
        be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF
        THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
        SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the
        Services from time to time are hereby expressly incorporated herein by
        reference. We reserve the right, in our sole discretion, to make changes
        or modifications to these Legal Terms at any time and for any reason. We
        will alert you about any changes by updating the 'Last updated' date of
        these Legal Terms, and you waive any right to receive specific notice of
        each such change. It is your responsibility to periodically review these
        Legal Terms to stay informed of updates. You will be subject to, and
        will be deemed to have been made aware of and to have accepted, the
        changes in any revised Legal Terms by your continued use of the Services
        after the date such revised Legal Terms are posted.
      </p>
      <p>
        The Services are intended for users who are at least 13 years of age.
        All users who are minors in the jurisdiction in which they reside
        (generally under the age of 18) must have the permission of, and be
        directly supervised by, their parent or guardian to use the Services. If
        you are a minor, you must have your parent or guardian read and agree to
        these Legal Terms prior to you using the Services.
      </p>
      <p>
        We recommend that you print a copy of these Legal Terms for your
        records.
      </p>

      <h3>TABLE OF CONTENTS</h3>
      <ol>
        <li>OUR SERVICES</li>
        <li>INTELLECTUAL PROPERTY RIGHTS</li>
        <li>USER REPRESENTATIONS</li>
        <li>USER REGISTRATION</li>
        <li>PRODUCTS</li>
        <li>PURCHASES AND PAYMENT</li>
        <li>RETURN POLICY</li>
        <li>PROHIBITED ACTIVITIES</li>
        <li>USER GENERATED CONTRIBUTIONS</li>
        <li>CONTRIBUTION LICENCE</li>
        <li>GUIDELINES FOR REVIEWS</li>
        <li>THIRD-PARTY WEBSITES AND CONTENT</li>
        <li>SERVICES MANAGEMENT</li>
        <li>PRIVACY POLICY</li>
        <li>TERM AND TERMINATION</li>
        <li>MODIFICATIONS AND INTERRUPTIONS</li>
        <li>GOVERNING LAW</li>
        <li>DISPUTE RESOLUTION</li>
        <li>CORRECTIONS</li>
        <li>DISCLAIMER</li>
        <li>LIMITATIONS OF LIABILITY</li>
        <li>INDEMNIFICATION</li>
        <li>USER DATA</li>
        <li>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
        <li>MISCELLANEOUS</li>
        <li>CONTACT US</li>
      </ol>

      <h2>1. OUR SERVICES</h2>
      <p>
        The information provided when using the Services is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who
        choose to access the Services from other locations do so on their own
        initiative and are solely responsible for compliance with local laws, if
        and to the extent local laws are applicable.
      </p>

      <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
      <h3>Our intellectual property</h3>
      <p>
        We are the owner or the licensee of all intellectual property rights in
        our Services, including all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics
        in the Services (collectively, the 'Content'), as well as the
        trademarks, service marks, and logos contained therein (the 'Marks').
      </p>
      <p>
        Our Content and Marks are protected by copyright and trademark laws (and
        various other intellectual property rights and unfair competition laws)
        and treaties in the United States and around the world.
      </p>
      <p>
        The Content and Marks are provided in or through the Services 'AS IS'
        for your personal, non-commercial use only.
      </p>
      <h3>Your use of our Services</h3>
      <p>
        Subject to your compliance with these Legal Terms, including the
        'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive,
        non-transferable, revocable licence to:
      </p>
      <ul>
        <li>access the Services; and</li>
        <li>
          download or print a copy of any portion of the Content to which you
          have properly gained access.
        </li>
      </ul>
      <p>solely for your personal, non-commercial use.</p>
      <p>
        Except as set out in this section or elsewhere in our Legal Terms, no
        part of the Services and no Content or Marks may be copied, reproduced,
        aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise
        exploited for any commercial purpose whatsoever, without our express
        prior written permission.
      </p>
      <p>
        If you wish to make any use of the Services, Content, or Marks other
        than as set out in this section or elsewhere in our Legal Terms, please
        address your request to: 5tygaeats@gmail.com. If we ever grant you the
        permission to post, reproduce, or publicly display any part of our
        Services or Content, you must identify us as the owners or licensors of
        the Services, Content, or Marks and ensure that any copyright or
        proprietary notice appears or is visible on posting, reproducing, or
        displaying our Content.
      </p>
      <p>
        We reserve all rights not expressly granted to you in and to the
        Services, Content, and Marks.
      </p>
      <p>
        Any breach of these Intellectual Property Rights will constitute a
        material breach of our Legal Terms and your right to use our Services
        will terminate immediately.
      </p>
      <h3>Your submissions</h3>
      <p>
        Please review this section and the 'PROHIBITED ACTIVITIES' section
        carefully prior to using our Services to understand the (a) rights you
        give us and (b) obligations you have when you post or upload any content
        through the Services.
      </p>
      <h4>Submissions:</h4>
      <p>
        By directly sending us any question, comment, suggestion, idea,
        feedback, or other information about the Services ('Submissions'), you
        agree to assign to us all intellectual property rights in such
        Submission. You agree that we shall own this Submission and be entitled
        to its unrestricted use and dissemination for any lawful purpose,
        commercial or otherwise, without acknowledgment or compensation to you.
      </p>
      <h4>You are responsible for what you post or upload:</h4>
      <p>By sending us Submissions through any part of the Services you:</p>
      <ul>
        <li>
          confirm that you have read and agree with our 'PROHIBITED ACTIVITIES'
          and will not post, send, publish, upload, or transmit through the
          Services any Submission that is illegal, harassing, hateful, harmful,
          defamatory, obscene, bullying, abusive, discriminatory, threatening to
          any person or group, sexually explicit, false, inaccurate, deceitful,
          or misleading;
        </li>
        <li>
          to the extent permissible by applicable law, waive any and all moral
          rights to any such Submission;
        </li>
        <li>
          warrant that any such Submission are original to you or that you have
          the necessary rights and licences to submit such Submissions and that
          you have full authority to grant us the above-mentioned rights in
          relation to your Submissions; and
        </li>
        <li>
          warrant and represent that your Submissions do not constitute
          confidential information.
        </li>
      </ul>
      <p>
        You are solely responsible for your Submissions and you expressly agree
        to reimburse us for any and all losses that we may suffer because of
        your breach of (a) this section, (b) any third party’s intellectual
        property rights, or (c) applicable law.
      </p>

      {/* 3. USER REPRESENTATIONS */}
      <h2>3. USER REPRESENTATIONS</h2>
      <p>
        By using the Services, you represent and warrant that: (1) all
        registration information you submit will be true, accurate, current, and
        complete; (2) you will maintain the accuracy of such information and
        promptly update such registration information as necessary; (3) you have
        the legal capacity and you agree to comply with these Legal Terms; (4)
        you are not under the age of 13; (5) you are not a minor in the
        jurisdiction in which you reside, or if a minor, you have received
        parental permission to use the Services; (6) you will not access the
        Services through automated or non-human means, whether through a bot,
        script or otherwise; (7) you will not use the Services for any illegal
        or unauthorised purpose; and (8) your use of the Services will not
        violate any applicable law or regulation.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Services (or any
        portion thereof).
      </p>

      {/* 4. USER REGISTRATION */}
      <h2>4. USER REGISTRATION</h2>
      <p>
        You may be required to register to use the Services. You agree to keep
        your password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>

      {/* 5. PRODUCTS */}
      <h2>5. PRODUCTS</h2>
      <p>
        We make every effort to display as accurately as possible the colours,
        features, specifications, and details of the products available on the
        Services. However, we do not guarantee that the colours, features,
        specifications, and details of the products will be accurate, complete,
        reliable, current, or free of other errors, and your electronic display
        may not accurately reflect the actual colours and details of the
        products. All products are subject to availability, and we cannot
        guarantee that items will be in stock. We reserve the right to
        discontinue any products at any time for any reason. Prices for all
        products are subject to change.
      </p>

      {/* 6. PURCHASES AND PAYMENT */}
      <h2>6. PURCHASES AND PAYMENT</h2>
      <p>We accept the following forms of payment:</p>
      {/* Include payment options here */}

      {/* 7. RETURN POLICY */}
      <h2>7. RETURN POLICY</h2>
      <p>All sales are final and no refund will be issued.</p>

      {/* 8. PROHIBITED ACTIVITIES */}
      <h2>8. PROHIBITED ACTIVITIES</h2>
      <p>
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. The Services may not be used
        in connection with any commercial endeavours except those that are
        specifically endorsed or approved by us.
      </p>
      {/* Include prohibited activities list here */}
      <h2>9. USER GENERATED CONTRIBUTIONS</h2>
      <p>The Services does not offer users to submit or post content.</p>

      <h2>10. CONTRIBUTION LICENCE</h2>
      <p>
        You and Services agree that we may access, store, process, and use any
        information and personal data that you provide and your choices
        (including settings).
      </p>
      <p>
        By submitting suggestions or other feedback regarding the Services, you
        agree that we can use and share such feedback for any purpose without
        compensation to you.
      </p>

      <h2>11. GUIDELINES FOR REVIEWS</h2>
      <p>
        We may provide you areas on the Services to leave reviews or ratings.
        When posting a review, you must comply with the following criteria:
      </p>
      <ol>
        <li>
          you should have firsthand experience with the person/entity being
          reviewed;
        </li>
        <li>
          your reviews should not contain offensive profanity, or abusive,
          racist, offensive, or hateful language;
        </li>
        <li>
          your reviews should not contain discriminatory references based on
          religion, race, gender, national origin, age, marital status, sexual
          orientation, or disability;
        </li>
        <li>your reviews should not contain references to illegal activity;</li>
        <li>
          you should not be affiliated with competitors if posting negative
          reviews;
        </li>
        <li>
          you should not make any conclusions as to the legality of conduct;
        </li>
        <li>you may not post any false or misleading statements; and</li>
        <li>
          you may not organise a campaign encouraging others to post reviews,
          whether positive or negative.
        </li>
      </ol>

      <h2>12. THIRD-PARTY WEBSITES AND CONTENT</h2>
      <p>
        The Services may contain (or you may be sent via the Site) links to
        other websites ('Third-Party Websites') as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software, and other content or items
        belonging to or originating from third parties ('Third-Party Content').
        Such Third-Party Websites and Third-Party Content are not investigated,
        monitored, or checked for accuracy, appropriateness, or completeness by
        us, and we are not responsible for any Third-Party Websites accessed
        through the Services or any Third-Party Content posted on, available
        through, or installed from the Services, including the content,
        accuracy, offensiveness, opinions, reliability, privacy practices, or
        other policies of or contained in the Third-Party Websites or the
        Third-Party Content. Inclusion of, linking to, or permitting the use or
        installation of any Third-Party Websites or any Third-Party Content does
        not imply approval or endorsement thereof by us. If you decide to leave
        the Services and access the Third-Party Websites or to use or install
        any Third-Party Content, you do so at your own risk, and you should be
        aware these Legal Terms no longer govern. You should review the
        applicable terms and policies, including privacy and data gathering
        practices, of any website to which you navigate from the Services or
        relating to any applications you use or install from the Services. Any
        purchases you make through Third-Party Websites will be through other
        websites and from other companies, and we take no responsibility
        whatsoever in relation to such purchases which are exclusively between
        you and the applicable third party. You agree and acknowledge that we do
        not endorse the products or services offered on Third-Party Websites and
        you shall hold us blameless from any harm caused by your purchase of
        such products or services. Additionally, you shall hold us blameless
        from any losses sustained by you or harm caused to you relating to or
        resulting in any way from any Third-Party Content or any contact with
        Third-Party Websites.
      </p>

      <h2>13. SERVICES MANAGEMENT</h2>
      <p>We reserve the right, but not the obligation, to:</p>
      <ol>
        <li>monitor the Services for violations of these Legal Terms;</li>
        <li>
          take appropriate legal action against anyone who, in our sole
          discretion, violates the law or these Legal Terms, including without
          limitation, reporting such user to law enforcement authorities;
        </li>
        <li>
          in our sole discretion and without limitation, refuse, restrict access
          to, limit the availability of, or disable (to the extent
          technologically feasible) any of your Contributions or any portion
          thereof;
        </li>
        <li>
          in our sole discretion and without limitation, notice, or liability,
          to remove from the Services or otherwise disable all files and content
          that are excessive in size or are in any way burdensome to our
          systems; and
        </li>
        <li>
          otherwise manage the Services in a manner designed to protect our
          rights and property and to facilitate the proper functioning of the
          Services.
        </li>
      </ol>

      <h2>14. PRIVACY POLICY</h2>
      <p>
        We care about data privacy and security. By using the Services, you
        agree to be bound by our Privacy Policy posted on the Services, which is
        incorporated into these Legal Terms. Please be advised the Services are
        hosted in South Africa. If you access the Services from any other region
        of the world with laws or other requirements governing personal data
        collection, use, or disclosure that differ from applicable laws in South
        Africa, then through your continued use of the Services, you are
        transferring your data to South Africa, and you expressly consent to
        have your data transferred to and processed in South Africa.
      </p>

      <h2>15. TERM AND TERMINATION</h2>
      <p>
        These Legal Terms shall remain in full force and effect while you use
        the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
        WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
        OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES
        OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
        ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </p>
      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </p>

      <h2>16. MODIFICATIONS AND INTERRUPTIONS</h2>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Services at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Services. We also reserve the right to modify or discontinue all or part
        of the Services without notice at any time. We will not be liable to you
        or any third party for any modification, price change, suspension, or
        discontinuance of the Services.
      </p>
      <p>
        We cannot guarantee the Services will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Services, resulting in interruptions, delays,
        or errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Services at any time or for any
        reason without notice to you. You agree that we have no liability
        whatsoever for any loss, damage, or inconvenience caused by your
        inability to access or use the Services during any downtime or
        discontinuance of the Services. Nothing in these Legal Terms will be
        construed to obligate us to maintain and support the Services or to
        supply any corrections, updates, or releases in connection therewith.
      </p>
      <h2>17. GOVERNING LAW</h2>
      <p>
        These Legal Terms shall be governed by and defined following the laws of
        South Africa. 5TygaEats and yourself irrevocably consent that the courts
        of South Africa shall have exclusive jurisdiction to resolve any dispute
        which may arise in connection with these Legal Terms.
      </p>

      <h2>18. DISPUTE RESOLUTION</h2>
      <h3>Informal Negotiations</h3>
      <p>
        To expedite resolution and control the cost of any dispute, controversy,
        or claim related to these Legal Terms (each a 'Dispute' and
        collectively, the 'Disputes') brought by either you or us (individually,
        a 'Party' and collectively, the 'Parties'), the Parties agree to first
        attempt to negotiate any Dispute (except those Disputes expressly
        provided below) informally for at least thirty (30) days before
        initiating arbitration. Such informal negotiations commence upon written
        notice from one Party to the other Party.
      </p>
      <h3>Binding Arbitration</h3>
      <p>
        Any dispute arising out of or in connection with these Legal Terms,
        including any question regarding its existence, validity, or
        termination, shall be referred to and finally resolved by the
        International Commercial Arbitration Court under the European
        Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to
        the Rules of this ICAC, which, as a result of referring to it, is
        considered as the part of this clause. The number of arbitrators shall
        be three (3). The seat, or legal place, or arbitration shall be
        Johannesburg, South Africa. The language of the proceedings shall be
        English. The governing law of these Legal Terms shall be substantive law
        of South Africa.
      </p>
      <h3>Restrictions</h3>
      <p>
        The Parties agree that any arbitration shall be limited to the Dispute
        between the Parties individually. To the full extent permitted by law,
        (a) no arbitration shall be joined with any other proceeding; (b) there
        is no right or authority for any Dispute to be arbitrated on a
        class-action basis or to utilize class action procedures; and (c) there
        is no right or authority for any Dispute to be brought in a purported
        representative capacity on behalf of the general public or any other
        persons.
      </p>
      <h3>Exceptions to Informal Negotiations and Arbitration</h3>
      <p>
        The Parties agree that the following Disputes are not subject to the
        above provisions concerning informal negotiations binding arbitration:
        (a) any Disputes seeking to enforce or protect, or concerning the
        validity of, any of the intellectual property rights of a Party; (b) any
        Dispute related to, or arising from, allegations of theft, piracy,
        invasion of privacy, or unauthorized use; and (c) any claim for
        injunctive relief. If this provision is found to be illegal or
        unenforceable, then neither Party will elect to arbitrate any Dispute
        falling within that portion of this provision found to be illegal or
        unenforceable and such Dispute shall be decided by a court of competent
        jurisdiction within the courts listed for jurisdiction above, and the
        Parties agree to submit to the personal jurisdiction of that court.
      </p>

      <h2>19. CORRECTIONS</h2>
      <p>
        There may be information on the Services that contains typographical
        errors, inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies, or omissions and to change or update
        the information on the Services at any time, without prior notice.
      </p>

      <h2>20. DISCLAIMER</h2>
      <p>
        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
        THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
        EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
        IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES'
        CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
        THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
        (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
        PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
        FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS
        TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
        AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
        CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
        VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
        THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
        OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
        KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED,
        OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
        ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </p>

      <h2>21. LIMITATIONS OF LIABILITY</h2>
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
        USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
        OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
        OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,
        BY YOU TO US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
        LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
        CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
        DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
        ADDITIONAL RIGHTS.
      </p>

      <h2>22. INDEMNIFICATION</h2>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) use of the
        Services; (2) breach of these Legal Terms; (3) any breach of your
        representations and warranties set forth in these Legal Terms; (4) your
        violation of the rights of a third party, including but not limited to
        intellectual property rights; or (5) any overt harmful act toward any
        other user of the Services with whom you connected via the Services.
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defence and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defence of such claims. We will use reasonable efforts to
        notify you of any such claim, action, or proceeding which is subject to
        this indemnification upon becoming aware of it.
      </p>
      <h2>23. USER DATA</h2>
      <p>
        We will maintain certain data that you transmit to the Services for the
        purpose of managing the performance of the Services, as well as data
        relating to your use of the Services. Although we perform regular
        routine backups of data, you are solely responsible for all data that
        you transmit or that relates to any activity you have undertaken using
        the Services. You agree that we shall have no liability to you for any
        loss or corruption of any such data, and you hereby waive any right of
        action against us arising from any such loss or corruption of such data.
      </p>

      <h2>24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
      <p>
        Visiting the Services, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Services, satisfy any legal requirement that such communication
        be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SERVICES. You hereby waive any rights or requirements
        under any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>

      <h2>25. MISCELLANEOUS</h2>
      <p>
        These Legal Terms and any policies or operating rules posted by us on
        the Services or in respect to the Services constitute the entire
        agreement and understanding between you and us. Our failure to exercise
        or enforce any right or provision of these Legal Terms shall not operate
        as a waiver of such right or provision. These Legal Terms operate to the
        fullest extent permissible by law. We may assign any or all of our
        rights and obligations to others at any time. We shall not be
        responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or
        part of a provision of these Legal Terms is determined to be unlawful,
        void, or unenforceable, that provision or part of the provision is
        deemed severable from these Legal Terms and does not affect the validity
        and enforceability of any remaining provisions. There is no joint
        venture, partnership, employment or agency relationship created between
        you and us as a result of these Legal Terms or use of the Services. You
        agree that these Legal Terms will not be construed against us by virtue
        of having drafted them. You hereby waive any and all defences you may
        have based on the electronic form of these Legal Terms and the lack of
        signing by the parties hereto to execute these Legal Terms.
      </p>

      <h2>26. CONTACT US</h2>
      <p>
        In order to resolve a complaint regarding the Services or to receive
        further information regarding use of the Services, please contact us at:
      </p>
      <p>
        5TygaEats
        <br />
        __________
        <br />
        South Africa
        <br />
        Phone: 0658928881
        <br />
        Email: 5tygaeats@gmail.com
      </p>
    </div>
  );
};

export default TermsAndConditions;
